import Config from "../../conf/Config.js";
const state = {
    "rules": [],
    "filters": {
        "operator": "AND",
        "fields": [
            {"fieldFind":"mn_name", "key": "mn_id", "type": "text", "name": "Menu", "filter": true, "width": "140px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_status", "type": "text", "name": "View", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_add", "type": "text", "name": "Add", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_edit", "type": "text", "name": "Edit", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_delete", "type": "text", "name": "Del", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_print", "type": "text", "name": "Print", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            
            
            {"fieldFind":"", "key": "user_entry", "type": "text", "name": "User Entry", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "date_entry", "type": "text", "name": "Date Entry", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "user_edit", "type": "text", "name": "User Edit", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "date_edit", "type": "text", "name": "Date Edit", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "guru_menu_status", "type": "select", "name" : "Status", "filter" : true, "width" : "80px", "grid" : true, "valueFind": [{"key":"T","label":"Aktif"},{"key":"F","label":"Tidak Aktif"}], "sort": {"status": true, "asc": "-"}},
            
            
            {"fieldFind":"", "key": "guru_code", "type": "text", "name": "Guru", "filter": false, "width": "", "grid": false, "sort": {"status": false, "asc": "-"}},
            {"fieldFind":"", "key": "id", "type": "text", "name": "ID", "filter": false, "width": "", "grid": false, "sort": {"status": false, "asc": "-"}}
        ],
        "find": [],
        "order": "menu.mn_urut"
    },
    "recs": [],
    "rec": {},
    "pages" : {"halaman": 1, "batas": Config.perpage, "ttlhalaman": 0, "ttlrec": 0, "autoadd": "T"},
    "selectedRow": -1,
    "recDetails" : [],
    "recMenus" : []
};
const getters = {
    pages(state) {
        return state.pages;
    },
    selectedRow(state) {
        return state.selectedRow;
    },
    filters(state) {
        return state.filters;
    },
    rules(state) {
        return state.rules;
    },
    rec(state) {
        return state.rec;
    },
    recs(state) {
        return state.recs;
    },
    recDetails(state) {
        return state.recDetails;
    },
    recMenus(state) {
        return state.recMenus;
    },
    filterByShow(state){
        return state.filters.fields.filter((event) => {
            return event.grid === true;
        });
    },
    "getRecsParam": (state)=>(fields)=>{
        let obj = [];
        for (let index = 0; index < state.recs.length; index++) {
            const element = state.recs[index];
            if(element["guru_menu_status"]=="T"){
                let objf = {};
                for (let indexf = 0; indexf < fields.length; indexf++) {
                    const elementf = fields[indexf];
                    objf[elementf] = element[elementf];
                }
                obj.push(objf);
            }
        }
        return obj;
    }
};
const mutations = {
    setSelectedRow(state, payload){
        state.selectedRow = payload;
    },
    setRec(state, payload){
        state.rec = Object.assign({}, payload);
    },
    setRecs(state, payload){
        state.recs = payload;
    },

    addRecs(state, payload) {
        state.recs.splice(0, 0, payload);
    },
    editRecs(state, payload) {
        state.recs[payload.index] = payload.rec;
    },
    deleteRecs(state, payload) {
        state.recs.splice(payload,1);
    },
    setFilters(state, payload){
        state.filters = payload;
    },
    setPages(state, payload){
        state.pages = payload;
    },
    setRecMenus(state, payload){
        state.recMenus = payload;
    },
    loadMenuDetail(state, payload){
        // eslint-disable-next-line no-console
        // console.log("Load menu detail");
        let self = this;
        let tambah = "";
        for(let b=0; b<payload.urut; b++){
            tambah = `&nbsp;→&nbsp;${tambah}`;
        }
        payload.rec.map(function(value){
            // eslint-disable-next-line no-console
            // console.log("Nilai",value);
            if (value.mn_up==payload.kodeup){
                payload.recadd.push({
                    "mn_id" : value.mn_id,
                    "mn_name" : value.mn_name,
                    "mn_urut" : value.mn_urut,
                    "mn_type" : value.mn_type,
                    "children" : [],
                    "id": value.id
                });
                let sama = false ;
                if(state.recMenus.length>0){
                    if(state.recMenus[state.recMenus.length-1]["mn_id"]==value.mn_id) sama = true;
                }
                if (!sama) {
                    state.recMenus.push({
                        "mn_id": value.mn_id,
                        "mn_name": tambah + value.mn_name,
                        "mn_urut": value.mn_urut,
                        "guru_menu_status": value.guru_menu_status,
                        "guru_menu_add": value.guru_menu_add,
                        "guru_menu_edit": value.guru_menu_edit,
                        "guru_menu_delete": value.guru_menu_delete,
                        "guru_menu_print": value.guru_menu_print,
                        "mn_type": value.mn_type,
                        "id": value.id
                    });
                    /*if(value.guru_menu_status=="T"){
                        self.header["guru_menu_status"] = true;
                    }
                    if(value.guru_menu_add=="T"){
                        self.header["guru_menu_add"] = true;
                    }
                    if(value.guru_menu_edit=="T"){
                        self.header["guru_menu_edit"] = true;
                    }
                    if(value.guru_menu_delete=="T"){
                        self.header["guru_menu_delete"] = true;
                    }
                    if(value.guru_menu_print=="T"){
                        self.header["guru_menu_print"] = true;
                    }*/
                }
                if(value.mn_type=="F"){
                    let lanjut = payload.urut +1;
                    // eslint-disable-next-line no-undef
                    self.dispatch("modGuruMenu/loadMenuDetail", {
                        "rec": payload.rec,
                        "recadd": payload.recadd[payload.recadd.length-1]["children"], 
                        "kodeup": value.mn_id, 
                        "urut": lanjut
                    });
                    // self.loadMenuDetail(rec, recadd[recadd.length-1]["children"], value.menu_id, lanjut);
                }
            }
        });
        
    },
    loadData(state, vparams){
        const self = this;
        state.recDetails = [];
        state.recMenus = [];
        // eslint-disable-next-line no-undef
        vm.apiPost("/brantas/gurumenu/search", vparams, function(response){
            state.recs = response.rec==null?[]:response.rec;
            state.pages.ttlrec = parseFloat(response.ttlrec);
            state.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec) / state.pages.batas);
            // eslint-disable-next-line no-undef
            self.dispatch("modGuruMenu/loadMenuDetail", {
                "rec": state.recs, 
                "recadd": state.recDetails, 
                "kodeup": 0, 
                "urut": 0
            });
            // eslint-disable-next-line no-undef
            // console.log("Menu",state.recMenus);
        }, function(err){
            // eslint-disable-next-line no-console
            console.log(err);
        });
    }
};
const actions = {
    setSelectedRow: ({ commit }, payload) => {
        commit("setSelectedRow",payload);
    },
    setRecs: ({ commit }, payload) => {
        commit("setRecs",payload);
    },
    setRec: ({ commit }, payload) => {
        commit("setRec",payload);
    },
    addRecs: ({ commit }, payload) => {
        commit("addRecs",payload);
    },
    editRecs: ({ commit }, payload) => {
        commit("editRecs",payload);	
    },
    deleteRecs: ({ commit }, payload) => {
        commit("deleteRecs",payload);		
    },
	
    setPages:({ commit }, payload) => {
        commit("setPages",payload);
    },
    setFilters:({ commit }, payload) => {
        commit("setFilters",payload);
    },
    loadData: ({ commit }, payload) => {
        // eslint-disable-next-line no-console
        // console.log("Load data");
        commit("loadData",payload);		
    },
    loadMenuDetail: ({commit}, payload) => {
        commit("loadMenuDetail",payload);	
    },
    setRecMenus: ({commit}, payload) => {
        commit("setRecMenus",payload);	
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
