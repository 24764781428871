import Config from "../../conf/Config.js";
const state = {
    "rules": [],
    "filters": {
        "operator": "AND",
        "fields": [
            {"fieldFind":"", "key": "sekolah_kelas_name", "type": "text", "name": "Kelas", "filter": true, "width": "70px", "grid": true, "sort": {"status": true, "asc": "asc"}},
            {"fieldFind":"", "key": "siswa_nis", "type": "text", "name": "NIS", "filter": true, "width": "50px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"siswa_name", "key": "siswa_name", "type": "text", "name": "Nama", "filter": true, "width": "250px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_date", "type": "date", "name": "Tgl", "filter": true, "width": "80px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_time", "type": "date", "name": "Jam", "filter": true, "width": "70px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_lat", "type": "date", "name": "Lat", "filter": true, "width": "90px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_long", "type": "date", "name": "Long", "filter": true, "width": "90px", "grid": true, "sort": {"status": true, "asc": "-"}},
            
            {"fieldFind":"", "key": "user_entry", "type": "text", "name": "User Entry", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "date_entry", "type": "text", "name": "Date Entry", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "user_edit", "type": "text", "name": "User Edit", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "date_edit", "type": "text", "name": "Date Edit", "filter": false, "width": "150px", "grid": true, "sort": {"status": true, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_status", "type": "select", "name" : "Status", "filter" : true, "width" : "80px", "grid" : true, "valueFind": [{"key":"T","label":"Aktif"},{"key":"F","label":"Tidak Aktif"}], "sort": {"status": true, "asc": "-"}},
            
            
            {"fieldFind":"", "key": "siswa_code", "type": "text", "name": "Siswa", "filter": false, "width": "", "grid": false, "sort": {"status": false, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_foto", "type": "text", "name": "Foto", "filter": false, "width": "", "grid": false, "sort": {"status": false, "asc": "-"}},
            {"fieldFind":"", "key": "siswa_absen_code", "type": "text", "name": "ID", "filter": false, "width": "", "grid": false, "sort": {"status": false, "asc": "-"}}
        ],
        "find": [],
        "order": "",
        "fieldselect": "",
        "group": ""
    },
    "recs": [],
    "rec": {},
    "pages" : {"halaman": 1, "batas": Config.perpage, "ttlhalaman": 0, "ttlrec": 0, "autoadd": "T"},
    "selectedRow": -1
};
const getters = {
    pages(state) {
        return state.pages;
    },
    selectedRow(state) {
        return state.selectedRow;
    },
    filters(state) {
        return state.filters;
    },
    rules(state) {
        return state.rules;
    },
    rec(state) {
        return state.rec;
    },
    recs(state) {
        return state.recs;
    },
    filterByShow(state){
        return state.filters.fields.filter((event) => {
            return event.grid === true;
        });
    },
    "getRecsParam": (state)=>(fields)=>{
        let obj = [];
        for (let index = 0; index < state.recs.length; index++) {
            const element = state.recs[index];
            if(element["siswa_absen_status"]=="T"){
                let objf = {};
                for (let indexf = 0; indexf < fields.length; indexf++) {
                    const elementf = fields[indexf];
                    objf[elementf] = element[elementf];
                }
                obj.push(objf);
            }
        }
        return obj;
    }
};
const mutations = {
    setSelectedRow(state, payload){
        state.selectedRow = payload;
    },
    setRec(state, payload){
        state.rec = Object.assign({}, payload);
    },
    setRecs(state, payload){
        state.recs = payload;
    },

    addRecs(state, payload) {
        state.recs.splice(0, 0, payload);
    },
    editRecs(state, payload) {
        state.recs[payload.index] = payload.rec;
    },
    deleteRecs(state, payload) {
        state.recs.splice(payload,1);
    },
    setFilters(state, payload){
        state.filters = payload;
    },
    setPages(state, payload){
        state.pages = payload;
    },
    loadData(state){
        // console.log(state.filters);
        let vparams = {
            operator: state.filters.find.length > 0 ? state.filters.operator : "AND",
            kondisi: "",
            field: "",
            fieldvalue: "",
            limit: state.pages.batas,
            offset: state.pages.batas * (state.pages.halaman - 1),
            order: state.filters.order,
            fieldselect: state.filters.fieldselect,
            group: state.filters.group
        };

        if (state.filters.find.length > 0) {
            state.filters.find.map(function (value) {
                vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                if (vparams.fieldvalue !== "null") {
                    vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                } else {
                    vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                }
            });
        }

        // eslint-disable-next-line no-undef
        vm.apiPost("/brantas/siswaabsen/search", vparams, function(response){
            state.recs = response.rec==null?[]:response.rec;
            state.pages.ttlrec = parseFloat(response.ttlrec);
            state.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec) / state.pages.batas);
            // console.log("Isi recs", state.recs);
        }, function(err){
            // eslint-disable-next-line no-console
            console.log(err);
        });
    }
};
const actions = {
    setSelectedRow: ({ commit }, payload) => {
        commit("setSelectedRow",payload);
    },
    setRecs: ({ commit }, payload) => {
        commit("setRecs",payload);
    },
    setRec: ({ commit }, payload) => {
        commit("setRec",payload);
    },
    addRecs: ({ commit }, payload) => {
        commit("addRecs",payload);
    },
    editRecs: ({ commit }, payload) => {
        commit("editRecs",payload);	
    },
    deleteRecs: ({ commit }, payload) => {
        commit("deleteRecs",payload);		
    },
	
    setPages:({ commit }, payload) => {
        commit("setPages",payload);
    },
    setFilters:({ commit }, payload) => {
        commit("setFilters",payload);
    },
    loadData: ({ commit }, payload) => {
        // eslint-disable-next-line no-console
        console.log("Load data");
        commit("loadData",payload);		
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
