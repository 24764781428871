const state = {
    "recAkses": {}
};
const getters = {
    recAkses(state) {
        return state.recAkses;
    }
};
const mutations = {
	
};
const actions = {

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};