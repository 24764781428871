<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<style>
</style>

<script type="text/javascript">

export default {
    head: {
        title: {
            inner: "de-web"
        },
        script: [
            
        ]
    },
    name : localStorage.title,
    data() {
        return {
            
        };
    },
    methods :{
    
        
    },
    mounted(){
        
    }
};
</script>