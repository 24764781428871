<template>
    <div>
        <div style="position: relative;" :class="(errorMessage!=='' && showMessage)?'w3-border w3-border-red w3-round-large':''">
            <slot name="field"></slot>
            <i :title="(errorMessage!='')?'Tidak Valid':'Valid'" :class="(errorMessage=='')?'fa fa-check w3-text-blue':'fa fa-question-circle w3-text-red'" style="position: absolute;top: 12px;right: 10px;"></i>
        </div>
        <span v-show="errorMessage!='' && showMessage" class="w3-text-red w3-tiny" style="padding-left: 8px;">{{errorMessage}}</span>
        <!-- <span class="tooltiptext tooltiptop">{{(errorMessage!='')?'Tidak Valid':'Valid'}}</span> -->
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'DeObject',
    props: {
        errorMessage : {
            type : String,
            default : ''
        },
        showMessage : {
            type : Boolean,
            default : false
        },
        DModel : {
            type : String,
            default : ''
        },
        DRules : {
            type : Object,
            default: () => {}
        }
    },
    data() {
        return {
            
        }
    },
    watch: {
		DModel(baru, lama) {
            let self = this;
            let isValid = true;
            let msg = "";
            // console.log(self.DRules);
            if(self.DRules!=undefined){
                if(self.DRules.rules.required ){
                    if(self.DRules.rules.type =="String"){
                        if(baru.length==0){
                            isValid = false;
                            msg = `${self.DRules.title} Tidak boleh kosong`;
                        }else if(baru.length< self.DRules.rules["min-length"]){
                            isValid = false;
                            msg = `${self.DRules.title} harus lebih besar ${self.DRules.rules["min-length"]}`;
                        }else if(baru.length> self.DRules.rules["max-length"]){
                            isValid = false;
                            msg = `${self.DRules.title} harus lebih besar dari ${self.DRules.rules["max-length"]}`;
                        }
                    }else{
                        if(baru.length==0){
                            isValid = false;
                            msg = `${self.DRules.title} Tidak boleh kosong`;
                        }
                    }
                }
            }
            try {
                if(!isValid){
                    // this.showMessage = true;
                    this.errorMessage = msg;
                }else{
                    // this.showMessage = false
                    this.errorMessage = '';
                }
            } catch (error) {
                console.log(error);
            }
		},
	},
}
</script>