/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";

import Config from "@/conf/Config.js";
import axios from "axios";

Vue.use(VueHead);
Vue.use(Router);
/** sweet alert */
// eslint-disable-next-line no-unused-vars
import swal from "./assets/swal/sweet-alert.min.js";
require("./assets/swal/sweet-alert.css");
require("./assets/swal/theme.css");

// import wysiwyg from "vue-wysiwyg";
// Vue.use(wysiwyg, {});


const router = new Router({
    // mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/login",
            name: "Login",
            props: true,
            component: () => import("./views/public/Login.vue")
        },{
            path: "/",
            name: "Main",
            component: () => import("./views/admin/Induk.vue"),
            meta: {
                auth: true,
                title : "Dashboard"
            },
            children: [
                {
                    path: "/",
                    name: "Dashboard",
                    component: () => import("./views/admin/Dashboard.vue"),
                    meta: {
                        auth: true,
                        title : "Main",
                        tips: "Dashboard",
                        icon: "fa fa-home", 
                        closable: false,
                        tabClass: "w3-orange"
                    }
                },
                {
                    path: "/sekolah",
                    name: "Sekolah",
                    component: () =>  import("./views/admin/Sekolah.vue") ,
                    meta: {
                        auth: true,
                        title : "Sekolah",
                        icon: "fa fa-home",
                        closable: true
                    }
                },
                {
                    path: "/guru",
                    name: "Guru",
                    component: () =>  import("./views/admin/Guru.vue") ,
                    meta: {
                        auth: true,
                        title : "Guru",
                        icon: "fa fa-users",
                        closable: true
                    }
                },
                {
                    path: "/kelas",
                    name: "Kelas",
                    component: () =>  import("./views/admin/SekolahKelas.vue") ,
                    meta: {
                        auth: true,
                        title : "Kelas",
                        icon: "fa fa-home",
                        closable: true
                    }
                },
                {
                    path: "/matapelajaran",
                    name: "Matapelajaran",
                    component: () =>  import("./views/admin/Matapelajaran.vue") ,
                    meta: {
                        auth: true,
                        title : "Matapelajaran",
                        icon: "fa fa-home",
                        closable: true
                    }
                },
                {
                    path: "/siswa",
                    name: "Siswa",
                    component: () =>  import("./views/admin/Siswa.vue") ,
                    meta: {
                        auth: true,
                        title : "Siswa",
                        icon: "fa fa-home",
                        closable: true
                    }
                },

                {
                    path: "/profile",
                    name: "Profile",
                    component: () =>  import("./views/admin/Profile.vue") ,
                    meta: {
                        auth: true,
                        title : "Profile",
                        icon: "fa fa-user",
                        closable: true
                    }
                },
                {
                    path: "/kehadiran",
                    name: "Kehadiran",
                    component: () => import("./views/admin/Kehadiran.vue"),
                    meta: {
                        auth: true,
                        title : "Kehadiran",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/penilaian",
                    name: "Penilaian",
                    component: () => import("./views/admin/Penilaian.vue"),
                    meta: {
                        auth: true,
                        title : "Penilaian",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/kegiatan",
                    name: "Kegiatan",
                    component: () => import("./views/admin/Kegiatan.vue"),
                    meta: {
                        auth: true,
                        title : "Kegiatan",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/konsultasi",
                    name: "Konsultasi",
                    component: () => import("./views/admin/Konsultasi.vue"),
                    meta: {
                        auth: true,
                        title : "Konsultasi",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/diskusi",
                    name: "Diskusi",
                    component: () => import("./views/admin/Diskusi.vue"),
                    meta: {
                        auth: true,
                        title : "Diskusi",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/galery",
                    name: "Galery",
                    component: () => import("./views/admin/Galery.vue"),
                    meta: {
                        auth: true,
                        title : "Galery",
                        icon: "fa fa-magic",
                        closable: true
                    }
                },
                {
                    path: "/limaceria",
                    name: "Limaceria",
                    component: () => import("./views/admin/Limaceria.vue"),
                    meta: {
                        auth: true,
                        title : "Limaceria",
                        icon: "fa fa-pdf",
                        closable: true
                    }
                },
                {
                    path: '*',
                    component: () => import("./views/public/NoPage.vue"),
                    name: "NoPageAnak",
                    meta: {
                        auth: false,
                        title : "Not Found",
                        icon: "fa fa-magic",
                        closable: true
                    }
                }
            ]
        },
        {
            path: '*',
            component: () => import("./views/public/NoPage.vue"),
            meta: {
                auth: false,
                title : "Not Found",
                icon: "fa fa-magic",
                closable: true
            }
        }
    ]
});

function checkLogin(success = function(dt) {}, errorFn = function(dt) {}){
    let myHeaders = new Headers();
    myHeaders.append("authorization", (localStorage.brantastoken==undefined ? "-":localStorage.brantastoken));

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };
      
    fetch(Config.apiUrl + "/tproject/admin/checklogin", requestOptions).then((response) => {
        if (!response.ok) {
            if (response.status == 401) {
                vm.$router.push({ name: "Login" });
                return response
                    .text()
                    .then((err) => Promise.reject(err));
            }
            try {
                return response
                    .json()
                    .then((err) => Promise.reject(err));
            } catch (e) {
                return response
                    .text()
                    .then((err) => Promise.reject(err));
            }
        }
        return response.json();
    })
    .then(success)
    .catch(errorFn);
}

router.beforeEach((to, from, next) => {
    try {
        // console.log(to);
        if ( to.naml){
            next("/login");
        }
        if (to.matched.some(record => record.meta.auth)) {
            checkLogin(function(res){
                if (res.success == true) {
                    next();
                }else{
                    next("/login");    
                }
            }, function(err){
                next("/login");
            });
        } else {
            next();
            
        }
    } catch (error) {
        console.log('router error1', error);
        next("/login");
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn"t an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start();
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
});

export default router;
