<template>
    <div class="w3-bar w3-theme-d4 " style="margin-bottom: 2px;">
        <a title="Add" v-show="statusAdd" class="w3-bar-item w3-btn w3-border-right" @click="showAdd()"><i class="fa fa-plus"></i><span class="w3-hide-small"> Add</span></a>
        
        <a title="Refresh" v-show="statusRefresh" class="w3-bar-item w3-btn w3-border-right" @click="showRefresh()"><i class="fa fa-refresh"></i><span class="w3-hide-small"> Refresh</span></a>
        <a title="Export" v-show="statusExport" class="w3-bar-item w3-btn w3-border-right" @click="generate"><i class="fa fa-file-excel-o"></i><span class="w3-hide-small"> Export</span></a>
        <a title="Print" v-show="statusPrint" class="w3-bar-item w3-btn w3-border-right" @click="showPrint()"><i class="fa fa-print" ></i><span class="w3-hide-small"> Print</span></a>
        <slot name="button"></slot>
        <div v-show="statusFilter" class="w3-bar-item w3-btn w3-border-right" style="margin: 0;padding: 2px;">
            <select v-on:change="setTypeQuery(filters.findQuery[0])" v-model="filters.findQuery[0]['field']" class="w3-select w3-small" style="width:100px;height: 34px;">
                <option value="">.: Cari :.</option>
                <option  v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">{{fl.name}}</option>
            </select>
        </div>
        <div v-show="statusFilter" class="w3-bar-item w3-btn" style="margin: 0;padding: 2px 0 2px 2px;">
            <input v-on:keyup.enter="prosesFilter" v-if="filters.findQuery[0].type=='text'" type="text" class="w3-input w3-small" v-model="filters.findQuery[0].fieldValue" style="width:200px;display: inline-block!important; margin-right:5px;">

            <select  v-on:change="prosesFilter" v-if="filters.findQuery[0].type=='select'" v-model="filters.findQuery[0].fieldValue" class="w3-select w3-small" style="width:200px;display: inline-block!important; margin-right:0px;height: 34px;">
                <option  v-for="fl in filters.findQuery[0].valueFind" :key="fl.key" :value="fl.key">{{fl.label}}</option>
            </select>

            <div class="w3-text-black" v-if="filters.findQuery[0].type=='date'" style="position: relative;width:200px;display: inline-block!important; margin-right:5px;">
                <input v-on:click="filters.findQuery[0].showdate=true" readonly type="text" class="w3-input w3-small" placeholder="Tanggal" v-model="filters.findQuery[0].fieldValue">
                <transition name="calendar-fade">
                    <date-picker  @close="filters.findQuery[0].showdate = false;prosesFilter()" v-if="filters.findQuery[0].showdate" v-model="filters.findQuery[0].fieldValue"></date-picker>
                </transition>
                <button v-on:click="filters.findQuery[0].showdate=true" type="button" class="w3-btn w3-small " style="position: absolute; top: 0px; right: 0px;"><i class="fa fa-calendar"></i></button>
            </div>
        </div>
        <!-- <div v-show="statusFilter" class="w3-bar-item w3-btn" style="margin: 0;padding: 2px 0 2px 0;">
            <button v-on:click="prosesFilter()" type="button" class="w3-btn w3-small w3-theme" style="margin-right:5px;"><i class="fa fa-search"></i> </button>
        </div> -->
        <a title="Filter" v-if="statusFilterAdvance" class="w3-bar-item w3-btn w3-border-right" @click="showFilterAdvance()"><i class="fa fa-filter"></i><span class="w3-hide-small"> Advance Filter</span></a>
        <slot name="buttonkanan"></slot>

        <a title="Left" v-if="statusLeft" class="w3-bar-item w3-btn w3-border-right" @click="showLeft()"><i class="fa fa-arrow-left"></i><span class="w3-hide-small"></span></a>
        <a title="Center" v-if="statusCenter" class="w3-bar-item w3-btn w3-border-right" @click="showCenter()"><i class="fa fa-columns"></i><span class="w3-hide-small"></span></a>
        <a title="Right" v-if="statusRight" class="w3-bar-item w3-btn w3-border-right" @click="showRight()"><i class="fa fa-arrow-right"></i><span class="w3-hide-small"></span></a>

        <div v-show="help!=''" class="w3-bar-item w3-btn w3-right" style="margin: 0;padding: 2px 0 2px 0;">
            <button title="Bantuan untuk menu ini" v-on:click="showHelp()" type="button" class="w3-btn w3-small w3-yellow " style="margin-right:2px;"><i class="fa fa-question"></i> </button>
        </div>

        <div id="idHelp" class="w3-modal">
            <vue-draggable-resizable :y="50"  :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:800px;">   
                    <header class="w3-container w3-theme-d3 drag w3-round-large" style="cursor:move;">
                        <span onclick="document.getElementById('idHelp').style.display='none'" class="w3-button w3-display-topright w3-round-large">&times;</span>
                        <h6><i class="fa fa-question"></i> Help</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <iframe style="    width: 100%;height: 400px;" :src="'https://www.youtube.com/embed/' + help" frameborder="0"></iframe>
                    </div>
                </div>
            </vue-draggable-resizable>
        </div>

        <!-- Advance Filter -->
        <div id="idfilter" class="w3-modal">
            <vue-draggable-resizable :y="60" :resizable="false" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-card-4 w3-animate-top" style="max-width:600px;">
                    <header class="w3-container w3-theme-d3 drag"> 
                        <span onclick="document.getElementById('idfilter').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-filter"></i> Filter</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <div class="w3-panel w3-theme-l1 w3-padding">
                            <div class="w3-row">
                                <div class="w3-col l12 m12 s12">
                                    <select v-model="filters.operator" class="w3-select w3-small" style="width:100px;height: 34px;">
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                    </select>
                                    <button v-on:click="addFilter()" type="button" class="w3-btn w3-small w3-theme-d5" style="margin-left:5px;"><i class="fa fa-plus"></i> Tambahkan Kondisi</button>
                                </div>
                            </div>
                        </div>

                        <div class="w3-panel w3-theme-l1 w3-padding">
                            <div class="w3-row" v-for="(item, index) in filters.findQuery" :key="index">
                                <div class="w3-col l12 m12 s12">
                                    <select v-on:change="setTypeQuery(item)" v-model="item.field" class="w3-select w3-small" style="width:150px;height: 34px;">
                                        <option  v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">{{fl.name}}</option>
                                    </select>
                                    <span>&nbsp;</span>
                                    <select v-model="item.kondisi" class="w3-select w3-small" style="width:50px;margin-right:5px!important;height: 34px;">
                                        <option v-for="fl in filters.kondisi" :key="fl.key" :value="fl.key">{{fl.key}}</option>
                                    </select>
                                    <input v-if="item.type=='text'" type="text" class="w3-input w3-small" v-model="item.fieldValue" style="width:270px;display: inline-block!important; margin-right:5px;">

                                    <select v-if="item.type=='select'" v-model="item.fieldValue" class="w3-select w3-small" style="width:270px;display: inline-block!important; margin-right:5px;height: 34px;">
                                        <option  v-for="fl in item.valueFind" :key="fl.key" :value="fl.key">{{fl.label}}</option>
                                    </select>

                                    <div v-if="item.type=='date'" style="position: relative;width:270px;display: inline-block!important; margin-right:5px;">
                                        <input v-on:click="item.showdate=true" readonly type="text" class="w3-input w3-small" placeholder="Tanggal" v-model="item.fieldValue">
                                        <transition name="calendar-fade">
                                            <date-picker  @close="item.showdate = false" v-if="item.showdate" v-model="item.fieldValue"></date-picker>
                                        </transition>
                                        <button v-on:click="item.showdate=true" type="button" class="w3-btn w3-small " style="position: absolute; top: 0px; right: 0px;"><i class="fa fa-calendar"></i></button>
                                    </div>

                                    <button v-on:click="removefilter(index)" type="button" class="w3-btn w3-small w3-red" style="margin-left:5px;"><i class="fa fa-remove"></i></button>
                                </div>
                            </div>
                        </div> 

                    </div>
                    <footer class="w3-container w3-theme-l1">
                        <h6 class="w3-right">
                            <button v-on:click="prosesFilter()" type="button" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-search"></i> Filter</button>
                            <button onclick="document.getElementById('idfilter').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </vue-draggable-resizable>
        </div>
        <!-- Advance Filter -->

    </div>
    
</template>
<script>
// import download from "../assets/js/download.js";
/**
* Untuk download excel menggunakan https://github.com/jecovier/vue-json-excel 
*/
export default {
    components: {
        
    },
    data () {
        return {
            filters : {
                operator:"AND", 
                kondisi :[
                    {key : "="},
                    {key : "like"},
                    {key : ">"},
                    {key : ">="},
                    {key : "<"},
                    {key : "<="},
                    {key : "!="}
                ],
                findQuery : [
                    {field : "" , kondisi:"like", fieldValue : "",type:"text", showdate : false}
                ]
            }
        };
    },
    props: {
        "fieldFind" : {
            type: Array,
            required: true
        },
        setFilter: {
            type: Function,
            required: true,
            default: () => { }
        },
        clickAdd: {
            type: Function,
            default: () => { }
        },
        clickFilter: {
            type: Function,
            default: () => { }
        },
        clickRefresh: {
            type: Function,
            default: () => { }
        },
        clickExport: {
            type: Function,
            default: () => { }
        },
        clickPrint: {
            type: Function,
            default: () => { }
        },
        clickLeft: {
            type: Function,
            default: () => { }
        },
        clickRight: {
            type: Function,
            default: () => { }
        },
        clickCenter: {
            type: Function,
            default: () => { }
        },
        /**Untuk export xls*/
        // mime type [xls, csv], default: xls
        "type" : {
            type: String,
            default: "xls"
        },
        // Json to download
        "data":{
            type: Array,
            required: false
        },
        "fields":{
            type: Object,
            required: false
        },
        "exportFields":{
            type: Object,
            required: false
        },
        "title":{
            default: null
        },
        "footer":{
            default: null
        },
        "name":{
            type: String,
            default: "data.xls"
        },
        "meta":{
            type: Array,
            default: () => []
        },
        "help":{
            type : String,
            default : ""
        },
        statusAdd : {
            type : Boolean,
            default : true
        },
        statusRefresh : {
            type : Boolean,
            default : true
        },
        statusExport : {
            type : Boolean,
            default : true
        },
        statusPrint : {
            type : Boolean,
            default : true
        },
        statusFilter : {
            type : Boolean,
            default : true
        },
        statusFilterAdvance :{
            type : Boolean,
            default : false
        },
        statusLeft :{
            type : Boolean,
            default : false
        },
        statusRight :{
            type : Boolean,
            default : false
        },
        statusCenter :{
            type : Boolean,
            default : false
        }
    },
    computed:{
        idName : function(){
            const now = new Date().getTime();
            return "export_"+now;
        },
        downloadFields: function(){
            if(this.fields != undefined)
                return this.fields;
            
            if(this.exportFields !== undefined)
                return this.exportFields;

            return "";
        }
    },
    methods : {
        showHelp(){
            document.getElementById("idHelp").style.display="block";
        },
        setTypeQuery(item){
            let obj = this.findObj(this.fieldFind,"key",item.field);
            // console.log(obj,item.field,this.fieldFind);
            if(obj==undefined){
                obj = this.findObj(this.fieldFind,"fieldFind",item.field);
            }
            item.type = obj.type;
            if(obj.type=="select"){
                item["valueFind"] = obj.valueFind;
            }else{
                item["valueFind"] = [];
                item["showdate"] = false;
                item["fieldValue"] = "";
            }
            // console.log(item, obj);
        },
        showFilterAdvance(){
            document.getElementById("idfilter").style.display="block";
        },
        addFilter(){
            let obj = this.setFilterField(this.fieldFind);
            this.filters.findQuery.push({field : obj[0]["kunci"] , kondisi:"like", fieldValue : "", type:obj[0]["type"]}); 
            // this.setTypeQuery(this.filters.findQuery[0])
        },
        prosesFilter(){
            // console.log(this.filters.findQuery, this.filters.operator);
            this.setFilter(this.filters.findQuery, this.filters.operator);
        },
        removefilter(idx){
            if(idx>0)
                this.$delete(this.filters.findQuery,idx);
        },
        showAdd (){
            this.clickAdd();
        },
        showRefresh (){
            this.clickRefresh();
        },
        showPrint (){
            this.clickPrint();
        },
        setFilterField(fl){
            let obj = [];
            if (fl==undefined){
                return obj;
            }
            fl.map(function (item) {
                if(item.filter==true){
                    if(item["fieldFind"]!=undefined){
                        if(item["fieldFind"]!=""){
                            item["kunci"] = item["fieldFind"];
                        }else{
                            item["kunci"] = item["key"];
                        }
                    }else{
                        item["kunci"] = item["key"];
                    }
                    obj.push(item);
                }
            });
            return obj;
        },
        /**Untuk excel*/
        generate() {
            if(!this.data.length){
                return;
            }
            let json = this.getProcessedJson(this.data, this.downloadFields);
            if(this.type == "csv"){
                return this.export(this.jsonToCSV(json), this.name, "application/csv");
            }
            return this.export(this.jsonToXLS(json), this.name, "application/vnd.ms-excel");
        },
        /*
        Use downloadjs to generate the download link
        */
        export (data, filename, mime) {
            let blob = this.base64ToBlob(data, mime);
            this.downloadFile(filename, blob);
            // download(blob, filename, mime)
        },
        /*
        jsonToXLS
        ---------------
        Transform json data into an xml document with MS Excel format, sadly
        this format show a prompt when open due to a default behavior
        on Microsoft office. It's recommended to use CSV format instead.
        */
        jsonToXLS (data) {
            let xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>${table}</table></body></html>';
            let xlsData = "<thead><tr>";
            const colspan = Object.keys(data[0]).length;
            //Header
            if( this.title != null ){
                xlsData += this.parseExtraData(this.title, '<th colspan="'+colspan+'">${data}<th></tr><tr>');
            }
            //Fields
            for (let key in data[0]) {
                xlsData += "<th>" + key + "</th>";
            }
            xlsData += "</tr></thead>";
            xlsData += "<tbody>";
            //Data
            data.map(function (item) {
                xlsData += "<tbody><tr>";
                for (let key in item) {
                    xlsData += "<td>" + item[key] + "</td>";
                }
                xlsData += "</tr></tbody>";
            });
            //Footer
            if( this.footer != null ){
                xlsData += "<tfooter><tr>";
                xlsData += this.parseExtraData(this.footer, '<td colspan="'+colspan+'">${data}<td></tr><tr>');
                xlsData += "</tr></tfooter>";
            }
            return xlsTemp.replace("${table}", xlsData);
        },
        /*
        jsonToCSV
        ---------------
        Transform json data into an CSV file.
        */
        jsonToCSV (data) {
            let csvData = [];
            //Header
            if( this.title != null ){
                csvData.push(this.parseExtraData(this.title, "${data}\r\n"));
            }
            //Fields
            for (let key in data[0]) {
                csvData.push(key);
                csvData.push(",");
            }
            csvData.pop();
            csvData.push("\r\n");
            //Data
            data.map(function (item) {
                for (let key in item) {
                    let escapedCSV = item[key] + ""; // cast Numbers to string
                    if (escapedCSV.match(/[,"\n]/)) {
                        escapedCSV = '"' + escapedCSV.replace(/"/g, "\"\"") + '"';
                    }
                    csvData.push(escapedCSV); 
                    csvData.push(",");
                }
                csvData.pop();
                csvData.push("\r\n");
            });
            //Footer
            if( this.footer != null ){
                csvData.push(this.parseExtraData(this.footer, "${data}\r\n"));
            }
            return csvData.join("");
        },
        /*
        getProcessedJson
        ---------------
        Get only the data to export, if no fields are set return all the data
        */
        getProcessedJson: function(data, header){
            let keys = this.getKeys(data, header);
            let newData = [];
            let _self = this;
            data.map(function (item) {

                let newItem = {};
                for( let label in keys){
                    // var iii= item;
                    let property = keys[label];
                    newItem[label] = _self.getNestedData(property, item);
                }
                newData.push(newItem);
            });
            return newData;
        },
        getKeys: function(data, header){
            if( header ){
                return header;
            }
            let keys = {};
            for (let key in data[0]) {
                keys[key] = key;
            }
            return keys;
        },
        /*
        parseExtraData
        ---------------
        Parse title and footer attribute to the csv format
        */
        parseExtraData(extraData, format){
            let parseData = "";
            if( Array.isArray(extraData) ){
                for (let i = 0; i < extraData.length; i++) {
                    parseData += format.replace("${data}", extraData[i]);
                }
            }
            else{
                parseData += format.replace("${data}", extraData);
            }
            return parseData;
        },
        callItemCallback: function(field, itemValue) {
            if (typeof field === "object" && typeof field.callback === "function") {
                return field.callback(itemValue);
            }
            return itemValue;
        },
        getNestedData: function(key, item) { 
            const field = (typeof key === "object") ? key.field : key; 
  
            let valueFromNestedKey = null ;
            let keyNestedSplit = field.split(".") ;
  
            valueFromNestedKey = item[keyNestedSplit[0]] ;
            for (let j = 1; j < keyNestedSplit.length; j++) { 
                valueFromNestedKey = valueFromNestedKey[keyNestedSplit[j]] ;
            } 
  
            valueFromNestedKey = this.callItemCallback(key, valueFromNestedKey); 
  
            return valueFromNestedKey; 
        },
        base64ToBlob (data, mime) {
            let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            let bstr = atob(base64);
            let n = bstr.length;
            let u8arr = new Uint8ClampedArray(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        showLeft (){
            this.clickLeft();
        },
        showRight (){
            this.clickRight();
        },
        showCenter (){
            this.clickCenter();
        }
    }
};
</script>