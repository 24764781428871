import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import mixin from "./mixin.js";
import { store } from "./store/deStore.js";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
// eslint-disable-next-line no-unused-vars
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("@/assets/swal/theme.css");

// Untuk Form
import { ModelListSelect } from "@/components/vue-select";
import VueDraggableResizable from "vue-draggable-resizable";
import { Money } from "v-money";
import Paging from "@/components/Paging.vue";
import Toolbar from "@/components/Toolbar.vue";
import DeObject from "@/components/Form/DeObject.vue";
import VueScrollingTable from "@/components/tablescroll/VueScrollingTable.vue";
import DatePicker from "@/components/DatePicker.vue";
import DeLoading from "@/components/DeLoading.vue";

import VueMoment from "vue-moment";
import moment from "moment-timezone";

// import RouterTab from "./routerTab";
// import lokalmixin from "./lokal-mixin.js";
require("@/assets/css/style-admin.css");
Vue.component("ModelListSelect", ModelListSelect);
Vue.component("VueDraggableResizable", VueDraggableResizable);
Vue.component("Money", Money);
Vue.component("Paging", Paging);
Vue.component("Toolbar", Toolbar);
Vue.component("DeObject", DeObject);
// Vue.component(moment);
Vue.component("VueScrollingTable", VueScrollingTable);
Vue.component("DatePicker", DatePicker);
Vue.component("DeLoading", DeLoading);

Vue.use(VueMoment, {
    moment
});

Vue.config.productionTip = false;
// Vue.use(RouterTab);

Vue.use(VueToast, {
    position: "top",
    duration: 3000
});
// mixin.methods["mixinproject"] = ()=> {return lokalmixin.methods };
Vue.mixin(mixin);
const vm = new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App),
    created () {
        // this.$store.commit("addRoute", lokalmixin.routes);
    }
}).$mount("#app");
global.vm = vm;