/* eslint-disable */
import config from '@/conf/Config.js';
import axios from 'axios';
// import VueMoment from 'vue-moment';
axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common['authorization'] = localStorage.brantastoken;
import md5 from "@/assets/doevent/md5.js";

export default {
    data(){
        return {
            appName : config.appName,
            perPage : config.perpage,
            apiUrl : config.apiUrl,
        }
    },
    methods:{
        checkLogin(success = function(dt) {}, errorFn = function(dt) {}){
            let myHeaders = new Headers();
            myHeaders.append("authorization", (localStorage.brantastoken==undefined ? "-":localStorage.brantastoken));
        
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
              
            fetch(config.apiUrl + "/tproject/admin/checklogin", requestOptions).then((response) => {
                if (!response.ok) {
                    if (response.status == 401) {
                        vm.$router.push({ name: "Login" });
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                    try {
                        return response
                            .json()
                            .then((err) => Promise.reject(err));
                    } catch (e) {
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                }
                return response.json();
            })
            .then(success)
            .catch(errorFn);
        },
		getObjFromStoreage(key){
			return JSON.parse(localStorage.getItem(key));
		},
        calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;
          
            // calculate the width and height, constraining the proportions
            if (width > height) {
              if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }
            }
            return [width, height];
        },
        compressImage(file, fn){
            const self = this;
            const MAX_WIDTH = 1000;
            const MAX_HEIGHT = 1000;
            const MIME_TYPE = "image/jpeg";
            const QUALITY = 1;
            // const file = ev.target.files[0]; // get the file
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                // Handle the failure properly
                console.log("Cannot load image");
                fn("Cannot load image");
            };
            return img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = self.calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                // fn(canvas);
                canvas.toBlob(async function(blob) {
                    let fileupload = new File([blob], file.name, { type: "image/jpeg" });
                    fn(fileupload);
                }, MIME_TYPE, QUALITY);
            }; 
        },
        displayInfo(label, file) {
            const p = document.createElement('p');
            p.innerText = `${label} - ${this.readableBytes(file.size)}`;
            document.getElementById('root').append(p);
        },
        
        readableBytes(bytes) {
            const i = Math.floor(Math.log(bytes) / Math.log(1024)),
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            
            return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
        },
        calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;
          
            // calculate the width and height, constraining the proportions
            if (width > height) {
              if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }
            }
            return [width, height];
        },
        slice(array, start, end){
            let length = array == null ? 0 : array.length;
            if (!length) {
                return [];
            }
            start = start == null ? 0 : start;
            end = end === undefined ? length : end;
            if (start < 0) {
                start = -start > length ? 0 : length + start;
            }
            end = end > length ? length : end;
            if (end < 0) {
                end += length;
            }
            length = start > end ? 0 : (end - start) >>> 0;
            start >>>= 0;
            let index = -1;
            const result = new Array(length);
            while (++index < length) {
                result[index] = array[index + start];
            }
            return result;
        },
        setFilterHeaders(orders){
            const self = this;
            orders.fields.map(function(val){
                if(val.sort.asc!="-"){
                    if(val.sort.asc=="asc"){
                        val.sort.asc="-";
                    }else if(val.sort.asc=="desc"){
                        val.sort.asc="asc";
                    }
                    self.setFilterHeader(val,orders);
                }
            });
        },
        setFilterHeader(dt,order){
            if(dt['sort']['status']){
                let filter = dt.fieldFind == ''?  dt.key : dt.fieldFind;
                filter = filter + ' ' + dt['sort']['asc'];
                order['order'] = order['order'].replace(","+filter,'');
                order['order'] = order['order'].replace(filter,'');
                if(order['order'][0]==','){
                    order['order'] = order['order'].substring(1, order['order'].length);
                }
                dt['sort']['asc'] = (dt['sort']['asc']=='asc')?'desc':((dt['sort']['asc']=='-')?'asc':'-');
                filter = dt.fieldFind == ''?  dt.key : dt.fieldFind;
                filter = filter + ' ' + dt['sort']['asc'] ;
                if(dt['sort']['asc']!='-') {
                    if(order['order']!=''){
                        order['order'] = order['order']+","+filter;
                    }else{
                        order['order'] += filter;
                    }
                }
            }else{
                return false;
            }
        },
        getNow(format){
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            if(format=='id'){
                today = `${dd}-${mm}-${yyyy}`;
            }else{
                today = `${yyyy}-${mm}-${dd}`;
            }
            return today;
        },
        showLog(msg){
            if(process.env.NODE_ENV !== 'production'){
                console.log("Isi Pesan = ",msg);
            }
        },
        showLoading(title){
            this.setTextLoading(title);
            document.getElementById('idLoading').style.display='block';
        },
        setTextLoading(title){
            document.getElementById('txt-loading').innerHTML = title;
        },
        hideLoading(){
            document.getElementById('idLoading').style.display='none';
        },
        getValid(rec,rules){
            let isValid=true;
            let self = this;
            for(let dt in rec){
                // console.log(rec[dt]);
                let field = self.findObj(rules,"field",dt);
                // console.log(dt, field);
                if(field!=undefined){
                    if(field.rules.required ){
                        if(["String","text","select"].includes(field.rules.type)){
                            if(rec[dt].length==0){
                                isValid = false;
                                console.log(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                                vm.$toast.error(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                            }
                            if(rec[dt].length> field.rules["max-length"]){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} harus lebih besar dari ${field.rules["max-length"]} huruf`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} harus lebih besar dari ${field.rules["max-length"]}`  ,'',300));
                            }
                            if(rec[dt].length< field.rules["min-length"]){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} harus lebih besar ${field.rules["min-length"]} huruf`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} harus lebih besar ${field.rules["min-length"]} huruf`  ,'',300));
                            }
                        }else{
                            if(rec[dt].length==0){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                            }
                        }
                    }
                }
            }
            return isValid;
        },
        setToast(title, content, icon, width){
            icon = (icon==''?'':icon);
            width = (width==''?'200':width);
            let hd = (icon!="")?`<div class="w3-col l2">
            <i class="fa ${icon}"></i>
        </div>`:'';
            let str  = `<div class="w3-row" style="width:${width}px;">
                ${hd}
                <div class="w3-rest">
                    <b><u>${title}</u></b>
                    <div>${content}</div>
                </div>
            </div>`;
            return str;
        },
        firstUpperCase(kata) {
            return kata.charAt(0).toUpperCase() + kata.slice(1)
        },        
        toDateServer:function(value){
            let data = value.split("/");
            return data[2]+"-"+data[1]+"-"+data[0];
        },
        apiGetParams(params){
            let keys = Object.keys(params);
            var parameters="";
            keys.forEach(function(key){
                console.log(params[key])
                var value = params[key];
                parameters += `${key}=${value}&`
            });
            return parameters;
        },
        async favoritMenu(id){
            axios.post('/post/admin/favoritmenu',{mn_id:id})
        },
        getCode(){
            // let obj = await axios.post(`/tproject/admin/getcode`);
            // return obj.data.key;
            return md5(Math.random().toString()+'mbahsomo');
        },
        async deAsync(url,params){
            let obj = await axios.post(url,params);
            return obj;
        },
        dePost(url,params, calback){
            axios.post(url,params).then(calback);
        },
        async apiPost(url, params, success = function(dt) {}, errorFn = function(dt) {} ) {
            if(params==undefined){
                params = {};
            }
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            const controller = new AbortController();
            const timeoutId = setTimeout(function(){ 
                controller.abort();
                errorFn("Server tidak merespon " + url);
            }, 5000);

            fetch(this.apiUrl +  url, {
                signal: controller.signal,
                method: "POST",
                headers: {authorization: localStorage.brantastoken , "Content-Type": "application/json"},
                body: JSON.stringify(params),
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status == 401) {
                        vm.$router.push({ name: "Login" });
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                    try {
                        return response
                            .json()
                            .then((err) => Promise.reject(err));
                    } catch (e) {
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                }
                clearTimeout(timeoutId);
                return response.json();
            })
            .then(success)
            .catch(errorFn);
        },
        async apiAsync(url, params) {
            if(params==undefined){
                params = {};
            }
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            let response = await fetch(this.apiUrl +  url, {
                method: "POST",
                headers: {authorization: localStorage.brantastoken, "Content-Type": "application/json" },
                body: JSON.stringify(params),
            });

            if (!response.ok) {
                if (response.status == 401) {
                    vm.$router.push({ name: "Login" });
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
                try {
                    return response
                        .json()
                        .then((err) => Promise.reject(err));
                } catch (e) {
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
            }
            let obj = await response.json(); 
            return obj;
        },
        apiUpload: function(url,file, params, callback = function(json) {}, error = function(json) {}, progress = function(evt) {}) {
            if(params==undefined){
                params = {};
            }
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            let request = new XMLHttpRequest();
            let formData = new FormData();
            formData.append("file", file);
            if (params!='') {
                // formData.append()
                params.map(function (value) {
                    formData.append(value.key,value.val)
                })
            }
            request.open("POST", this.apiUrl +  url);
            request.onreadystatechange = function() {
                if (request.readyState === 4) {
                    if (request.status == 200 || request.status == 201) {
                        console.log(request.responseText);
                        callback(JSON.parse(request.responseText));
                    } else {
                        error(JSON.parse(request.responseText));
                    }
                }
            };
            request.setRequestHeader("authorization", localStorage.brantastoken);
            request.upload.addEventListener("progress", progress);
            request.send(formData);
        },
        deDownloadFile(url, params, calback){
            let self = this;
            self.showLoading("Download File ...");
            let number = 0;
            if(url==''){
                url = this.setting.url + '/download';
            }
            NProgress.set(0.0);
            axios(
                {
                    url: url,method: 'POST',
                    responseType: 'blob', 
                    data : params,
                    onDownloadProgress : function (progressEvent) {
                        number = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                        // console.log(number, progressEvent);
                        NProgress.set(number/100);
                        self.setTextLoading(`Download ...${number} %`);
                        if(number>=100){
                            NProgress.set(1.0);
                            self.hideLoading();
                        }
                    }
                }
            ).then(calback);
        },
        async deUploadFile(url,file, param){
            let _this = this;
            let formData = new FormData();
            formData.append("file", file);
            if (param!='') {
                param.map(function (value) {
                    formData.append(value.key,value.val)
                })
            }
            let number = 0;
            let dt = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: function( progressEvent ) {
                    number = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                    _this.setTextLoading(`Proses upload ... ${number} %`);
                }.bind(this)
            });
            return dt;
        },
        formatNumber(val,decimals=0){
            if(val===null || val==""){val=0;return;}
            if(val!==null && val!=""&& val!="."){
            let rawValue = (val).toString();
            var decimal;
            if( (rawValue).includes(".") ){
                val = ( (rawValue).split(".")[0] ).replace(/,/g,"");
                decimal = ((rawValue).split(".")[1]).length==0?"00000":(rawValue).split(".")[1];
                decimal=decimal.slice(0,decimals);
            }else{
                val = (rawValue).replace(/,/g,"");
                decimal="00";
            }
            let formattedVal = parseFloat(val).toLocaleString('en');
            val=formattedVal+((rawValue).includes(".")?`${decimals==0?"":"."+decimal}`:"");
            }
            return val;
        },
        number(val){
            let data=val;
            try{
                data =  parseFloat( (val.toString()).replace(/,/g,"") );
            }catch(e){
                data =  0;
            }
            return data;
            return this.formatNumber(data);
        },
        eventGrid(self, grid, selectedRow, recs){
            window.addEventListener('keydown', function(evt) {
                // self.showLog(self.$route.name)
                // self.showLog(self.$options.name);
                // self.showLog(self[grid]);
                if( self.$route.name.toUpperCase() == self.$options.name.toUpperCase() && self[grid]){
                    let baris =  document.getElementById(grid+'-'+self[selectedRow]) ;
                    switch (evt.code) {
                        case 'ArrowUp':
                            if(self[selectedRow]>0){
                                if(baris.previousElementSibling!=null)
                                    baris.previousElementSibling.focus();
                                self[selectedRow]--;
                            }
                            break;
                        case 'ArrowDown' :
                            if(self[selectedRow]<(self[recs].length-1)){
                                if(baris.nextElementSibling!=null)
                                    baris.nextElementSibling.focus();
                                self[selectedRow]++;
                            }
                            break;
                    }
                }
            });
        },
        eventGridStore(self, grid, selectedRow, fn){
            window.addEventListener('keydown', function(evt) {
                if( self.$route.name.toUpperCase() === self.$options.name.toUpperCase() && self[grid]){
                    let baris =  document.getElementById(grid+'-'+vm.$store.getters[selectedRow]) ;
                    switch (evt.code) {
                        case 'ArrowUp':
                            if(vm.$store.getters[selectedRow]==-1){
                                fn(0);
                                // vm.$store.getters[selectedRow] = 0;
                                document.getElementById(grid+'-'+vm.$store.getters[selectedRow]).focus();
                            }else{
                                if(baris.previousElementSibling!=null){
                                    baris.previousElementSibling.focus();
                                    fn(vm.$store.getters[selectedRow]-1);
                                }
                            }
                            break;
                        case 'ArrowDown' :
                            if(vm.$store.getters[selectedRow]==-1){
                                // vm.$store.getters[selectedRow] = 0;
                                fn(0);
                                document.getElementById(grid+'-'+vm.$store.getters[selectedRow]).focus();
                            }else{
                                if(baris.nextElementSibling!=null){
                                    baris.nextElementSibling.focus();
                                    fn(vm.$store.getters[selectedRow]+1);
                                }
                            }
                            break;
                    }
                }
            });
        },
        getGridColumnmAutoWidth(id, kolom, retur){
            let x = document.getElementById(id);
            let lebar = 0;
            kolom.map(function(val){
                if(val.width!='' && val.width!='auto'){
                    lebar = lebar + parseInt(val.width.replace("px", ""));
                    val['width-numeric'] = parseInt(val.width.replace("px", ""));
                }
            });
            if(!retur){
                kolom.map(function(val){
                    if(val.width=='auto'){
                        val.width = (parseFloat(x.offsetWidth) - parseFloat(lebar)-65) + "px";
                        val['width-numeric'] = (parseFloat(x.offsetWidth) - parseFloat(lebar)-65);
                    }
                });
            }else{
                return (parseFloat(x.offsetWidth) - parseFloat(lebar)-65);
            }
        },
        incDay(tgl, plus){
            var tzOff = tgl.getTimezoneOffset() * 60 * 1000,
                t = tgl.getTime(),
                d = new Date(),
                tzOff2;

            t += (1000 * 60 * 60 * 24) * plus;
            d.setTime(t);

            tzOff2 = d.getTimezoneOffset() * 60 * 1000;
            if (tzOff != tzOff2) {
                var diff = tzOff2 - tzOff;
                t += diff;
                d.setTime(t);
            }

            return d;
        },
        lastDayInMonth(bln,thn){
            return new Date(thn, bln, 0).getDate();
        },
        downloadFile(filename, text, type){
            // 'data:text/plain;charset=utf-8,'
            var element = document.createElement('a');
            element.setAttribute('href', type +";charset=utf-8,"+ encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        downloadImage(url){
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "gambar.jpeg";
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);
        },
        jsonToQuery(json) {
            let str = "";
            for (var pkey in json) {
                if (str == "") {
                    str = pkey + "=" + json[pkey];
                } else {
                    str = str + "&" + pkey + "=" + json[pkey];
                }
            }
            return str;
        },
        // export default  {    
        sethighlight(text, phrase) {
            if (phrase)
                text = text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="w3-orange">$1</span>');
            return text;
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        setTerbilang(bilangan) {
            let kalimat = "";
            let angka = new Array('0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0');
            let kata = new Array('', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan');
            let tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');
            bilangan = bilangan + "";
            let panjang_bilangan = bilangan.length ;
            if(panjang_bilangan > 15){
                kalimat = "Diluar Batas";
            }else{
                /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
                for(i = 1; i <= panjang_bilangan; i++) {
                    angka[i] = bilangan.substr(-(i),1);
                }
                
                var i = 1;
                var j = 0;
                
                /* mulai proses iterasi terhadap array angka */
                while(i <= panjang_bilangan){
                    subkalimat = "";
                    kata1 = "";
                    kata2 = "";
                    kata3 = "";
                    
                    /* untuk Ratusan */
                    if(angka[i+2] != "0"){
                        if(angka[i+2] == "1"){
                            kata1 = "Seratus";
                        }else{
                            kata1 = kata[angka[i+2]] + " Ratus";
                        }
                    }
                    
                    /* untuk Puluhan atau Belasan */
                    if(angka[i+1] != "0"){
                        if(angka[i+1] == "1"){
                            if(angka[i] == "0"){
                                kata2 = "Sepuluh";
                            }else if(angka[i] == "1"){
                                kata2 = "Sebelas";
                            }else{
                                kata2 = kata[angka[i]] + " Belas";
                            }
                        }else{
                            kata2 = kata[angka[i+1]] + " Puluh";
                        }
                    }
                    
                    /* untuk Satuan */
                    if (angka[i] != "0"){
                        if (angka[i+1] != "1"){
                            kata3 = kata[angka[i]];
                        }
                    }
                    
                    /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
                    if ((angka[i] != "0") || (angka[i+1] != "0") || (angka[i+2] != "0")){
                        subkalimat = kata1+" "+kata2+" "+kata3+" "+tingkat[j]+" ";
                    }
                    
                    /* gabungkan variabe sub kalimat (untuk Satu blok 3 angka) ke variabel kalimat */
                    kalimat = subkalimat + kalimat;
                    i = i + 3;
                    j = j + 1;
                }
                
                /* mengganti Satu Ribu jadi Seribu jika diperlukan */
                if ((angka[5] == "0") && (angka[6] == "0")){
                    kalimat = kalimat.replace("Satu Ribu","Seribu");
                }
            }
            return kalimat;
        },
        printHtml(textHtml, kertas, bentuk, margin){
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if(element!=null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print { 
                        @page { 
                            size: ${kertas} ${bentuk}; 
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>				
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.log(textHtml);
            newWin.document.close();
            
        },
        setRomawi(x){
            let abil = ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
            if (x < 11)
                return abil[x];
            else if (x < 20)
                return abil[10] + this.setRomawi(x - 10) ;
            else if (x < 100)
                return this.setRomawi(x / 10)  + this.setRomawi(x % 10);
            else if (x < 200)
                return  this.setRomawi(x - 100);
            else if (x < 1000)
                return this.setRomawi(x / 100) + this.setRomawi(x % 100);
            else if (x < 2000)
                return  this.setRomawi(x - 1000);
            else if (x < 1000000)
                return this.setRomawi(x / 1000)  + this.setRomawi(x % 1000);
            else if (x < 1000000000)
                return this.setRomawi(x / 1000000)  + this.setRomawi(x % 1000000);
        },
        setFocus(nextElm){
            document.getElementById(nextElm).focus();
            return false;
        },
        setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie() {
            let user = getCookie("username");
            if (user != "") {
                alert("Welcome again " + user);
            } else {
                user = prompt("Please enter your name:", "");
                if (user != "" && user != null) {
                    setCookie("username", user, 365);
                }
            }
        },
        toText(text, val){
            let valtext = "";
            for (let index = 0; index < (val-(text+"").length); index++) {
                valtext += "0";
            }
            return valtext + text;
        },
        async getAksesMenu(){
            const self = this; 
            let urlinduk = vm.$route.path;
            let obj = await self.apiAsync("/tproject/usersmenu/search",{
                "operator":"AND",
                "kondisi":"=;=",
                "field":"users_menu.users_id;menu_url",
                "fieldvalue":localStorage.username + ";" + urlinduk,
                "limit":1,
                "offset":0,
                "fieldselect":"users_menu_add as add, users_menu_edit as edit, users_menu_delete as delete, users_menu_print as print, users_menu.id",
            });
            // console.log(obj);
            if (parseInt(obj.ttlrec) >0){
                let objd = await self.apiAsync("/tproject/usersmenu/search",{
                    "operator":"AND",
                    "kondisi":"=",
                    "field":"users_menu.id",
                    "fieldvalue": obj.rec[0]["id"] +"",
                    "limit":100,
                    "offset":0,
                    "fieldselect":"json_array_elements(users_menu_detail->'menu')->>'url' as url, json_array_elements(users_menu_detail->'menu')->>'status' as status",
                });
                obj.rec[0]["detail"] = objd.rec;
                return obj.rec[0];
            }
            return undefined;
        },
        checkEventAkses(menu, akses, detail, pesan){
            if (detail == "F") {
                if (akses=="A") {
                    if(menu.add!="T"){
                        if (pesan==undefined)
                        this.$toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses=="E") {
                    if(menu.edit!="T"){
                        if (pesan==undefined)
                            this.$toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses=="D") {
                    if(menu.delete!="T"){
                        if (pesan==undefined)
                            this.$toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses=="P") {
                    if(menu.delete!="T"){
                        if (pesan==undefined)
                            this.$toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
            }else{
                let ketemu = false;
                if (menu.detail!=undefined){
                    for (let index = 0; index < menu.detail.length; index++) {
                        const element = menu.detail[index];
                        if (element["url"]==akses){
                            if (element["status"]=="T"){
                                ketemu = true;
                            }
                        }
                    }
                }
                if(!ketemu){
                    if (pesan==undefined)
                            this.$toast.error("Tidak punya hak akses");
                    return false;
                }
            }
            return true;
        },
        dragElement(elmnt){
            let screenWidth = window.innerWidth;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let result = -1;
            let lebar = "";
            let selisih = 0;
            result = elmnt.style.width.indexOf("px");
            if(result!=-1){
                lebar = elmnt.style.width.replace("px","");
                selisih = (screenWidth - parseFloat(lebar)) /2;
            }
            result = elmnt.style.width.indexOf("%");
            if(result!=-1){
                lebar = elmnt.style.width.replace("%","");
                lebar = (lebar/100)*screenWidth;
                selisih = (screenWidth - parseFloat(lebar)) /2;
            }
		
			if (selisih>0){
				elmnt.style.left = selisih+ "px";
				if (document.getElementById(elmnt.id + "header")) {
				    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
				} else {
				    elmnt.onmousedown = dragMouseDown;
				}
				
				function dragMouseDown(e) {
				    e = e || window.event;
				    e.preventDefault();
				    pos3 = e.clientX;
				    pos4 = e.clientY;
				    document.onmouseup = closeDragElement;
				    document.onmousemove = elementDrag;
				}
				
				function elementDrag(e) {
				    e = e || window.event;
				    e.preventDefault();
				    pos1 = pos3 - e.clientX;
				    pos2 = pos4 - e.clientY;
				    pos3 = e.clientX;
				    pos4 = e.clientY;
				    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
				    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
				}
				
				function closeDragElement() {
				    document.onmouseup = null;
				    document.onmousemove = null;
				}
			}else{
				elmnt.style.position = "relative";
			}
            
        },
        queryObject(obj,key,filter){
            let hit = 0;
            let hsl = [];
            if(obj.length==0){
                return hsl;
            }
            obj.map(function(val){
                hit = 0;
                if (val[key].toUpperCase().indexOf(filter.toUpperCase()) > -1) {
                    hit = 1;
                }
                if(hit==1){
                    hsl.push(val);
                }
            });
            return hsl;
        }
    }        
}
